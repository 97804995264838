import { Video, useShareLinksLazyQuery } from 'api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TalkActionButton } from 'components/pages/talks/TalkActionButton';
import { useTalkPageContext } from 'components/pages/talks/slug/talk-page-context';
import ShareModal from 'components/share-modal';
import useVideoPlayerStore from 'components/video-player/store';
import mixpanel from 'lib/analytics/mixpanel';
import { IS_PROD } from 'lib/constants';
import { useDidMount } from 'lib/hooks/useDidMount';
import { sendErrorToServices } from 'lib/logging';
import { isUndefined } from 'lodash-es';
import { useRouter } from 'next/router';

type ShareProps = {
  slug: string;
  title: Video['title'];
  presenters: Video['presenterDisplayName'];
  context: string;
  shortenedUrl: string;
  customComponent?: React.ComponentType<{
    handleClick: () => void;
    isDarkMode: boolean;
  }>;
  onHideTooltip?: () => void;
  homepageTooltipIsOpen?: boolean;
  isDarkMode?: boolean;
  mixpanelTitle?: string;
};

const getEmbedCode = ({
  size = { x: 1024, y: 576 },
  slug,
  title,
  hideControls = false
}: Pick<ShareProps, 'slug' | 'title'> & {
  size?: { x: number; y: number };
  hideControls?: boolean;
}) => {
  return [
    `<div style="max-width:${size.x}px">`,
    '<div style="position:relative;height:0;padding-bottom:56.25%">',
    '<iframe ',
    `src="${IS_PROD ? 'https://embed.ted.com/talks' : `${global.origin}/embed`}/${slug}${hideControls ? '?hideControls=true' : ''}" `,
    `width="${size.x}px" `,
    `height="${size.y}px" `,
    `title="${title}" `,
    'style="position:absolute;left:0;top:0;width:100%;height:100%"  ',
    'frameborder="0" scrolling="no" ',
    'allowfullscreen ',
    `onload="window.parent.postMessage('iframeLoaded', 'https://embed.ted.com')">`,
    '</iframe>',
    '</div>',
    '</div>'
  ].join('');
};

const Share = ({
  ref,
  slug,
  title,
  presenters,
  context,
  shortenedUrl,
  customComponent: CustomComponent,
  onHideTooltip,
  homepageTooltipIsOpen = undefined,
  isDarkMode = false,
  mixpanelTitle
}: ShareProps & {
  ref: React.RefObject<HTMLDivElement | null>;
}) => {
  const router = useRouter();
  const isMounted = useDidMount();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('share');
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [wasVideoPlaying, setWasVideoPlaying] = useState(false);

  const isTalksPath = router.pathname.includes('/talks');

  const talkPageContext = isTalksPath ? useTalkPageContext() : null;
  const language = talkPageContext ? talkPageContext.language : undefined;

  const [loadQuery, { error, data }] = useShareLinksLazyQuery({
    variables: isTalksPath ? { slug, language } : { slug },
    ssr: false
  });

  useEffect(() => {
    const shouldLoadQuery =
      isMounted &&
      (isUndefined(homepageTooltipIsOpen) || homepageTooltipIsOpen);

    if (shouldLoadQuery) {
      loadQuery();
    }
  }, [isMounted, data, homepageTooltipIsOpen]);

  const video = data?.videos?.nodes?.[0];
  const canonicalUrl = video?.canonicalUrl;
  const native = video?.nativeDownloads;
  const audioDownload = video?.audioDownload;
  const nativeDownload = !!native && native.medium;

  const shareMessage = `${title} (${presenters} | ${context})`;
  const shareUrl = `${canonicalUrl}?utm_campaign=tedspread&utm_medium=referral&utm_source=tedcomshare`;

  const { onTalkShareStart, service, youTubePlayer } = useVideoPlayerStore(
    state => ({
      onTalkShareStart: state.onTalkShareStart,
      service: state.service,
      youTubePlayer: state.youTubePlayer
    })
  );

  const embedToDisplay = useMemo(() => {
    return getEmbedCode({
      slug,
      title,
      hideControls: true
    });
  }, [router.asPath]);

  const embedToCopy = useMemo(
    () =>
      getEmbedCode({
        slug,
        title
      }),
    [router.asPath]
  );

  const sendEvent = useCallback(
    label => {
      onTalkShareStart(label);
    },
    [onTalkShareStart]
  );

  const handleEmbedClick = useCallback(() => {
    setModalContent('embed');
  }, []);

  const handleShareClick = useCallback(() => {
    setModalContent('share');
    setIframeLoaded(false);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setModalContent('share');
  }, []);

  const handleIframeLoad = useCallback(() => {
    setIframeLoaded(true);
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.data === 'iframeLoaded' &&
        event.origin === 'https://embed.ted.com'
      ) {
        handleIframeLoad();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleIframeLoad]);

  useEffect(() => {
    const pauseVideo = () => {
      if (service === 'internal') {
        const mainVideo = document.getElementById('video') as HTMLVideoElement;
        if (mainVideo) {
          if (modalOpen && !wasVideoPlaying) {
            setWasVideoPlaying(!mainVideo.paused);
          }
          mainVideo.pause();
        }
      } else {
        if (youTubePlayer && typeof youTubePlayer.pauseVideo === 'function') {
          if (modalOpen && !wasVideoPlaying) {
            setWasVideoPlaying(youTubePlayer.getPlayerState() === 1);
          }
          youTubePlayer.pauseVideo();
        }
      }
    };

    const resumeVideo = () => {
      if (!wasVideoPlaying) return;

      if (service === 'internal') {
        const mainVideo = document.getElementById('video') as HTMLVideoElement;
        if (mainVideo) mainVideo.play();
      } else {
        if (youTubePlayer && typeof youTubePlayer.playVideo === 'function') {
          youTubePlayer.playVideo();
        }
      }
    };

    if (modalOpen && modalContent === 'embed') {
      pauseVideo();
    } else if (!modalOpen) {
      setIframeLoaded(false);
      resumeVideo();
      setWasVideoPlaying(false);
    }
  }, [modalContent, modalOpen, service, youTubePlayer, wasVideoPlaying]);

  if (error) {
    sendErrorToServices(error.message, 'Share Modal Error');
  }

  const renderDefaultComponent = () => (
    <TalkActionButton
      ref={buttonRef as React.RefObject<HTMLButtonElement | null>}
      iconName="send"
      onClick={() => setModalOpen(true)}
      label="Share"
    />
  );

  return (
    <div ref={ref as React.RefObject<HTMLDivElement>}>
      {CustomComponent ? (
        <CustomComponent
          handleClick={() => {
            if (onHideTooltip) onHideTooltip();

            if (mixpanelTitle)
              mixpanel.track('homepage_click', {
                current_url: window.location.href,
                title: mixpanelTitle,
                item_number: '',
                link_type: 'share',
                value: ''
              });

            setModalOpen(true);
          }}
          isDarkMode={isDarkMode}
        />
      ) : (
        renderDefaultComponent()
      )}
      <ShareModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        handleShareClick={handleShareClick}
        handleEmbedClick={handleEmbedClick}
        embedToCopy={embedToCopy}
        embedToDisplay={embedToDisplay}
        iframeLoaded={iframeLoaded}
        shareUrl={shareUrl}
        shareMessage={shareMessage}
        nativeDownload={nativeDownload}
        audioDownload={audioDownload}
        shortenedUrl={shortenedUrl}
        modalContent={modalContent}
        sendEvent={sendEvent}
      />
    </div>
  );
};

export default Share;

// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import shallow from 'zustand/shallow';

import {
  useIsItemInYourListLazyQuery,
  useRemoveVideoFromYourListMutation,
  useSaveVideoToYourListMutation
} from 'api';
import { sendErrorToServices } from 'lib/logging';

import useVideoPlayerStore from 'components/video-player/store';

import { ButtonTooltip } from 'components/@tedui/ButtonTooltip';
import { TalkActionButton } from 'components/pages/talks/TalkActionButton';
import mixpanel from 'lib/analytics/mixpanel';
import {
  AuthRedirectType,
  createSignInRedirectUrl,
  useActionOnRedirect,
  useAuthenticated
} from 'lib/auth';
import { useDidMount } from 'lib/hooks/useDidMount';
import useTooltipControls from 'lib/hooks/useTooltipControls';
import { isUndefined } from 'lodash-es';
import { useDidMount as useEffectOnMount } from 'rooks';
import { toast } from 'sonner';
import { SignInTooltip } from '../SignInTooltip';

type AddToListProps = {
  id: string;
  customComponent?: React.ComponentType<{
    handleClick: () => void;
    isActionRunning: boolean;
    isCurrentTalkInYourList: boolean;
    isDarkMode: boolean;
  }>;
  homepageTooltipIsOpen?: boolean;
  isDarkMode?: boolean;
  mixpanelTitle?: string;
};

const getItemId = (data: Record<string, { id?: string }>): string | null => {
  const dataKey = Object.keys(data)[0];
  return dataKey ? data[dataKey]?.id || null : null;
};

const AddToList = ({
  id,
  customComponent: CustomComponent,
  homepageTooltipIsOpen = undefined,
  isDarkMode = false,
  mixpanelTitle
}: AddToListProps) => {
  const loggedIn = useAuthenticated();
  const isMounted = useDidMount();
  const intl = useIntl();
  const { onAddToList } = useVideoPlayerStore(state => ({
    onAddToList: state.onAddToList
  }));
  const { hideTooltip, toggleToolTip, visibleTooltip } = useTooltipControls();
  const [itemIdToUse, setItemIdToUse] = useState<string>(id);
  const [redirectURL, setRedirectURL] = useState('ted.com');

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [
    loadQuery,
    {
      called,
      error: checkingInTalksError,
      data,
      loading: checkingInTalksLoading,
      refetch
    }
  ] = useIsItemInYourListLazyQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    ssr: false
  });

  if (checkingInTalksError) {
    sendErrorToServices('AddToList Error', checkingInTalksError);
  }

  useEffect(() => {
    const shouldLoadQuery =
      isMounted &&
      id &&
      !checkingInTalksLoading &&
      loggedIn &&
      (isUndefined(homepageTooltipIsOpen) || homepageTooltipIsOpen);

    if (shouldLoadQuery) {
      loadQuery();
    }

    if (data) {
      const newId = getItemId(data as Record<string, { id?: string }>);
      if (newId) {
        setItemIdToUse(newId);
      }
    }
  }, [isMounted, data, itemIdToUse, id, homepageTooltipIsOpen]);

  const isCurrentTalkInYourList =
    called && !checkingInTalksLoading && itemIdToUse !== id;

  // @ts-ignore
  const { currentTime } = useVideoPlayerStore(
    state => ({
      currentTime: state.roundedTime
    }),
    shallow
  );

  const [addToListMutation, { loading: isSaveRunning }] =
    useSaveVideoToYourListMutation({
      variables: {
        id: itemIdToUse,
        secondsPlayed: currentTime
      }
    });

  const [removeFromListMutation, { loading: isRemoveRunning }] =
    useRemoveVideoFromYourListMutation({
      variables: {
        id: itemIdToUse
      }
    });

  const isActionRunning =
    !called ||
    isSaveRunning ||
    isRemoveRunning ||
    (checkingInTalksLoading && isMounted);

  const addToListAction = async () => {
    await addToListMutation();
    await refetch();

    toast(
      <div>
        <p>
          {intl.formatMessage({
            defaultMessage: 'Talk added to your playlist.'
          })}
        </p>
        <p className="mt-2">
          {intl.formatMessage({
            defaultMessage: 'You can find this in your profile.'
          })}
        </p>
      </div>
    );
  };

  useActionOnRedirect(AuthRedirectType.AddToList, addToListAction);

  const removeFromListAction = async () => {
    await removeFromListMutation();
    await refetch();
    toast.success(
      intl.formatMessage({
        defaultMessage: 'This has been removed from your list.'
      })
    );
  };

  const handleClick = () => {
    if (!isCurrentTalkInYourList) {
      onAddToList();
      if (!loggedIn) {
        toggleToolTip('AddToList');
        return;
      }
    }

    const actionPromise = isCurrentTalkInYourList
      ? removeFromListAction
      : addToListAction;

    actionPromise().catch(error => {
      sendErrorToServices('Uncaught list action failure', error);
    });
  };

  useEffectOnMount(() => {
    const url = createSignInRedirectUrl(AuthRedirectType.AddToList, {
      id: itemIdToUse,
      secondsPlayed: currentTime
    });
    setRedirectURL(url);
  });

  const renderDefaultComponent = () => (
    <ButtonTooltip
      isVisible={visibleTooltip === 'AddToList'}
      onHide={hideTooltip}
      renderedTooltip={
        <SignInTooltip
          actionConclusionMessage="to add videos to your list"
          redirectURL={redirectURL}
        />
      }
      context="AddToLists"
    >
      <TalkActionButton
        ref={buttonRef}
        testId="AddToListsActionButton_TESTID"
        disabled={isActionRunning && loggedIn}
        onClick={handleClick}
        iconName={isCurrentTalkInYourList ? 'added-to-list' : 'add-to-list'}
        label={isCurrentTalkInYourList ? 'Remove' : 'Save'}
      />
    </ButtonTooltip>
  );
  return CustomComponent ? (
    <CustomComponent
      handleClick={() => {
        if (mixpanelTitle)
          mixpanel.track('homepage_click', {
            current_url: window.location.href,
            title: mixpanelTitle,
            item_number: '',
            link_type: 'save',
            value: ''
          });

        handleClick();
      }}
      isActionRunning={isActionRunning}
      isCurrentTalkInYourList={isCurrentTalkInYourList}
      isDarkMode={isDarkMode}
    />
  ) : (
    renderDefaultComponent()
  );
};

export default AddToList;

// TODO: Update social media icons to work with IcoMoon, WEB-424

import { LinkedInIcon } from 'components/@tedui/Icon/svgs/linkedIn';
import EmbedIcon from 'icons/EmbedIcon';
import FacebookIcon from 'icons/FacebookIcon';
import WhatsAppIcon from 'icons/WhatsappIcon';

const shareLinkMap = {
  embed: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center">
        <EmbedIcon className="size-full" />
      </div>
      <div className="text-xs">Embed</div>
    </>
  ),
  facebook: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center">
        <FacebookIcon variant="circle" className="size-full" />
      </div>
      <div className="text-xs">Facebook</div>
    </>
  ),
  linkedin: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center text-9xl">
        <LinkedInIcon className="size-full" />
      </div>
      <div className="text-xs">LinkedIn</div>
    </>
  ),
  twitter: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center text-9xl">
        <i
          aria-hidden="true"
          className="icon-twitter-circle-filled size-full text-twitter"
        />
      </div>
      <div className="text-xs">Twitter</div>
    </>
  ),
  sms: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
        <i
          aria-hidden="true"
          className="icon-message-square text-3xl text-gray-700"
        />
      </div>
      <div className="text-xs">SMS</div>
    </>
  ),
  whatsapp: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center">
        <WhatsAppIcon className="size-full" />
      </div>
      <div className="text-xs">WhatsApp</div>
    </>
  ),
  messenger: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
        <i
          aria-hidden="true"
          className="icon-facebook-messenger-filled text-3xl text-gray-700"
        />
      </div>
      <div className="text-xs">Messenger</div>
    </>
  ),
  mail: (
    <>
      <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
        <i aria-hidden="true" className="icon-mail text-3xl text-gray-700" />
      </div>
      <div className="text-xs">Email</div>
    </>
  )
};

export default shareLinkMap;

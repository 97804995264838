// @ts-strict-ignore
import { Icon } from 'components/@tedui';
import { Text } from 'components/typography';
import { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { FormattedMessage } from 'react-intl';

type ShareCopyLinkProps = {
  url: string;
  service: string;
  onCopy: () => void;
};

const CopyLink = ({ url, service, onCopy }: ShareCopyLinkProps) => {
  const [copied, setCopied] = useState(false);
  const [, copy] = useCopyToClipboard();

  const handleCopy = () => {
    copy(url);
    setCopied(true);
    onCopy();
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (copied) {
      timeoutId = setTimeout(() => setCopied(false), 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [copied]);

  return (
    <>
      <input
        disabled
        type="text"
        className="h-11 w-full rounded-xs border-thin border-gray-300 px-2 underline"
        aria-label="Canonical URL with UTM Params"
        value={url}
      />
      <button
        type="button"
        onClick={handleCopy}
        className="absolute right-1 top-2 flex h-8 w-max cursor-pointer rounded-sm bg-gray-300 p-2 px-4"
        aria-label={`Copy ${service} to clipboard`}
      >
        <div className="flex">
          <Icon className="mr-3 h-4 w-4 font-bold text-black" iconName="copy" />
          <Text size="s" className="font-bold text-black">
            {copied ? (
              <FormattedMessage defaultMessage="Copied!" />
            ) : (
              <FormattedMessage defaultMessage="Copy" />
            )}
          </Text>
        </div>
      </button>
    </>
  );
};

export default CopyLink;

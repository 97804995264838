// @ts-strict-ignore

import cx from 'classnames';
import useVideoPlayerStore from 'components/video-player/store';
import { openShareUrl } from 'lib/services';
import shareLinkMap from './shareLinkMap';

type ShareLinkProps = {
  mobile?: boolean;
  service: string;
  message?: string;
  url?: string;
  onClick?: () => void;
};

const ShareLink = ({
  mobile = false,
  service,
  message,
  url,
  onClick
}: ShareLinkProps) => {
  const { onTalkShareStart } = useVideoPlayerStore(state => ({
    onTalkShareStart: state.onTalkShareStart
  }));

  const buttonClassnames = cx(
    'mr-3 mt-2 flex w-14 flex-col items-center justify-center text-center md:w-auto',
    { 'md:hidden': mobile }
  );
  return (
    <button
      type="button"
      className={buttonClassnames}
      onClick={() => {
        if (onClick) onClick();
        onTalkShareStart(service);

        if (service !== 'embed') {
          openShareUrl({
            service,
            options: {
              message,
              url: url || ''
            }
          });
        }
      }}
    >
      {shareLinkMap[service]}
    </button>
  );
};

export default ShareLink;

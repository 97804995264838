import type { JSX } from 'react';

type EmbedIconProps = {
  className?: string;
};

const EmbedIcon = (props: EmbedIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      className={className || ''}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#596781"
      />
      <path
        d="M24 26L30 20L24 14"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 14L10 20L16 26"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EmbedIcon;

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

type Props = {
  redirectURL: string;
  actionConclusionMessage: string;
  bgColor?: string;
  className?: string;
};

const SignInTooltip = ({
  redirectURL,
  actionConclusionMessage,
  bgColor = 'bg-blue-300',
  className
}: Props): React.ReactNode => (
  <a
    href={redirectURL}
    className={cn(
      '-mb-2 flex flex-row flex-wrap rounded-sm p-3 text-white',
      className,
      bgColor
    )}
  >
    <span>
      <span className="mr-1 font-bold underline">
        <FormattedMessage defaultMessage="Sign-in " />
      </span>
      <FormattedMessage defaultMessage=" or " />
      <span className="mx-1 font-bold underline">
        <FormattedMessage defaultMessage="create an account" />
      </span>
      <FormattedMessage
        defaultMessage="{action}"
        values={{
          action: actionConclusionMessage
        }}
      />
    </span>
  </a>
);

export default SignInTooltip;

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Text } from 'components/@tedui';
import Divider from 'components/divider';
import Modal, { ModalType } from 'components/modal';
import CopyLink from 'components/pages/talks/slug/TalkDetails/TalkActions/CopyLink';
import DownloadLink from 'components/pages/talks/slug/TalkDetails/TalkActions/DownloadLink';
import ShareLink from 'components/pages/talks/slug/TalkDetails/TalkActions/ShareLink';
import SkeletonImage from 'components/skeleton-loader/image';
import { Heading, Microcopy } from 'components/typography';

type ShareProps = {
  modalContent: string;
  embedToCopy: string;
  embedToDisplay: string;
  shortenedUrl: string;
  shareUrl: string;
  modalOpen: boolean;
  iframeLoaded: boolean;
  nativeDownload: string;
  audioDownload: string;
  shareMessage: string;
  handleModalClose: () => void;
  handleEmbedClick: () => void;
  sendEvent: (param: string) => void;
  handleShareClick: () => void;
};

const ShareModal = ({
  handleModalClose,
  sendEvent,
  handleEmbedClick,
  handleShareClick,
  shortenedUrl,
  modalContent,
  modalOpen,
  shareUrl,
  shareMessage,
  embedToCopy,
  embedToDisplay,
  iframeLoaded,
  nativeDownload,
  audioDownload
}: ShareProps) => {
  return (
    <Modal
      type={modalContent === 'share' ? ModalType.Medium : ModalType.Large}
      isOpen={modalOpen}
      onDismiss={handleModalClose}
      ariaLabel="Share this talk"
      noOverflow={modalContent === 'share'}
      stickyCloseButton
      maxWidth
      modalClassName={cx({
        'xl:max-h-4/5-screen': modalContent === 'embed'
      })}
    >
      {modalContent === 'share' ? (
        <>
          <Heading size="s" className="mb-3 pt-12">
            <FormattedMessage defaultMessage="Share this talk" />
          </Heading>
          <Divider className="mb-3 w-full" />

          <div className="flex md:justify-between">
            <div className="share">
              <Microcopy size="m" className="text-gray-700">
                <FormattedMessage defaultMessage="Share" />
              </Microcopy>

              <div className="flex flex-wrap">
                <ShareLink service="embed" onClick={handleEmbedClick} />

                <ShareLink
                  service="facebook"
                  message={shareMessage}
                  url={shareUrl}
                />

                <ShareLink
                  service="linkedin"
                  message={shareMessage}
                  url={shareUrl}
                />
                <ShareLink
                  service="twitter"
                  message={shareMessage}
                  url={shortenedUrl || shareUrl}
                />
                <ShareLink
                  service="sms"
                  message={encodeURIComponent(shareMessage)}
                  url={shortenedUrl || shareUrl}
                  mobile
                />

                <ShareLink
                  service="whatsapp"
                  message={encodeURIComponent(shareMessage)}
                  url={shortenedUrl || shareUrl}
                  mobile
                />

                <ShareLink
                  service="messenger"
                  message={shareMessage}
                  url={shortenedUrl || shareUrl}
                  mobile
                />

                <ShareLink
                  service="mail"
                  message={encodeURIComponent(shareMessage)}
                  url={shortenedUrl || shareUrl}
                />
              </div>
            </div>
            <div className="download md:ml-10">
              <Microcopy size="m" className="mb-2 text-gray-700">
                <FormattedMessage defaultMessage="Download" />
              </Microcopy>

              <div className="flex">
                <DownloadLink
                  href={nativeDownload}
                  iconName="video"
                  downloadFormat="video"
                >
                  <FormattedMessage defaultMessage="MP4" />
                </DownloadLink>
                <DownloadLink
                  href={audioDownload}
                  iconName="headphones"
                  downloadFormat="audio"
                >
                  <FormattedMessage defaultMessage="Audio" />
                </DownloadLink>
              </div>
            </div>
          </div>
          <div className="mb-8 mt-7">
            <Text variant="body2">
              <FormattedMessage defaultMessage="Link" />
            </Text>
            <div className="relative mb-1 w-full">
              <CopyLink
                service="url"
                url={shareUrl}
                onCopy={() => sendEvent('link')}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <Heading size="s" className="mb-3 pt-2">
            <FormattedMessage defaultMessage="Embed this talk" />
          </Heading>
          <Divider className="mb-3 w-full" />

          <div className="relative mb-2 overflow-hidden">
            {!iframeLoaded && (
              <SkeletonImage className="aspect-h-9 aspect-w-16 absolute inset-0" />
            )}
            <div dangerouslySetInnerHTML={{ __html: embedToDisplay }} />
          </div>

          <div className="mb-4">
            <Text variant="body2">
              <FormattedMessage defaultMessage="Embed Code" />
            </Text>
            <div className="relative mb-1 w-full">
              <CopyLink
                service="embed"
                url={embedToCopy}
                onCopy={() => sendEvent('embed')}
              />
            </div>
          </div>

          <button
            type="button"
            className="mb-8 rounded-md bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300 focus:outline-none"
            onClick={handleShareClick}
          >
            <FormattedMessage defaultMessage="More Share Options" />
          </button>
        </>
      )}
    </Modal>
  );
};

export default ShareModal;

import cx from 'classnames';

import Icon from 'components/@tedui/Icon';
import { Text } from 'components/typography';
import useVideoPlayerStore from 'components/video-player/store';

import DownloadLinkProps from './DownloadLink.props';

const DownloadLink = ({
  href,
  iconName,
  children,
  downloadFormat
}: DownloadLinkProps & { children: React.ReactNode }) => {
  const { onDownload } = useVideoPlayerStore(state => ({
    onDownload: state.onDownload
  }));
  const hasLink = typeof href === 'string' && href !== '';
  const linkClass = cx('group mr-3 text-center outline-none', {
    'opacity-50': !hasLink
  });
  const iconWrapperClass = cx(
    'mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-300 ring-blue-700 ring-offset-2 group-focus-visible:ring-2',
    { 'group-hover:bg-gray-500': hasLink }
  );
  const iconClass = cx('text-3xl font-bold text-gray-700', {
    'group-hover:text-gray-300': hasLink
  });

  return (
    <a
      href={hasLink ? href : undefined}
      aria-disabled={!hasLink}
      className={linkClass}
      onClick={() => {
        onDownload(downloadFormat);
      }}
    >
      <div className={iconWrapperClass}>
        <Icon className={iconClass} iconName={iconName} />
      </div>
      <Text size="s">{children}</Text>
    </a>
  );
};

export default DownloadLink;

// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react';

import {
  SaveVideoToYourLikesMutationVariables,
  useIsItemInYourLikesLazyQuery,
  useRemoveVideoFromYourLikesMutation,
  useSaveVideoToYourLikesMutation
} from 'api';
import { TalkActionButton } from 'components/pages/talks/TalkActionButton';
import useVideoPlayerStore from 'components/video-player/store';
import {
  AuthRedirectType,
  createSignInRedirectUrl,
  useActionOnRedirect,
  useAuthenticated
} from 'lib/auth';
import { useDidMount } from 'lib/hooks/useDidMount';
import useTooltipControls from 'lib/hooks/useTooltipControls';
import { sendErrorToServices } from 'lib/logging';
import { useDidMount as useEffectOnMount } from 'rooks';
import { toast } from 'sonner';

import { ButtonTooltip } from 'components/@tedui/ButtonTooltip';
import { Link } from 'components/router';
import mixpanel from 'lib/analytics/mixpanel';
import { isUndefined } from 'lodash-es';
import { SignInTooltip } from '../SignInTooltip';

type LikeProps = {
  id: string;
  customComponent?: React.ComponentType<{
    handleClick: () => void;
    isActionRunning: boolean;
    isCurrentTalkInYourLikes: boolean;
    isDarkMode?: boolean;
  }>;
  homepageTooltipIsOpen?: boolean;
  isDarkMode?: boolean;
  mixpanelTitle?: string;
};

const getItemId = (data: Record<string, { id?: string }>): string | null => {
  const dataKey = Object.keys(data)[0];
  return dataKey ? data[dataKey]?.id || null : null;
};

const Like = ({
  id,
  customComponent: CustomComponent,
  homepageTooltipIsOpen = undefined,
  isDarkMode = false,
  mixpanelTitle
}: LikeProps) => {
  const loggedIn = useAuthenticated();
  const isMounted = useDidMount();
  const [itemIdToAdd, setItemIdToAdd] = useState<string>(id);
  const { hideTooltip, toggleToolTip, visibleTooltip } = useTooltipControls();
  const [redirectURL, setRedirectURL] = useState('ted.com');

  const buttonRef = useRef(null);
  const { onLike } = useVideoPlayerStore(state => ({
    onLike: state.onLike
  }));

  const [
    loadQuery,
    {
      called,
      loading: checkingInTalksLoading,
      error: checkingInTalksError,
      data,
      refetch
    }
  ] = useIsItemInYourLikesLazyQuery({
    variables: { id },
    ssr: false,
    fetchPolicy: 'cache-and-network'
  });

  if (checkingInTalksError) {
    sendErrorToServices('Like Error', checkingInTalksError);
  }

  useEffect(() => {
    const shouldLoadQuery =
      isMounted &&
      id &&
      !checkingInTalksLoading &&
      loggedIn &&
      (isUndefined(homepageTooltipIsOpen) || homepageTooltipIsOpen);

    if (shouldLoadQuery) {
      loadQuery();
    }

    if (data) {
      const newId = getItemId(data as Record<string, { id?: string }>);
      if (newId) {
        setItemIdToAdd(newId);
      }
    }
  }, [isMounted, data, loggedIn, id, homepageTooltipIsOpen]);

  const isCurrentTalkInYourLikes =
    called && !checkingInTalksLoading && itemIdToAdd !== id;

  const [likeVideoMutation, { loading: isSaveRunning }] =
    useSaveVideoToYourLikesMutation({ variables: { id: itemIdToAdd } });
  const [unlikeVideoMutation, { loading: isRemoveRunning }] =
    useRemoveVideoFromYourLikesMutation({ variables: { id: itemIdToAdd } });

  const likeVideoAction = async (
    variables?: SaveVideoToYourLikesMutationVariables
  ) => {
    const result = await likeVideoMutation(variables && { variables });
    await refetch();

    const errors = result?.data?.likeItem?.errors || [];
    if (errors.length > 0) {
      const errorMessage =
        errors[0] === 'Item was already favorited.'
          ? 'Find your liked talks in your profile.'
          : 'An error occurred while liking video.';
      toast(errorMessage);
      return;
    }

    toast.info(
      <div>
        Find your liked talks in{' '}
        <Link href="/dashboard/my-library" isZenithLink className="underline">
          My Library
        </Link>
        .
      </div>
    );
  };

  const unlikeVideoAction = async () => {
    const result = await unlikeVideoMutation();
    await refetch();

    const errors = result?.data?.unlikeItem?.errors || [];
    if (errors.length > 0) {
      toast.error('An error occurred while unliking video.');
      return;
    }
    toast.success('This has been removed from your liked talks.');
  };

  useActionOnRedirect(AuthRedirectType.Like, likeVideoAction);

  const isActionRunning =
    !called ||
    isSaveRunning ||
    isRemoveRunning ||
    (checkingInTalksLoading && isMounted);

  const handleClick = () => {
    if (!isCurrentTalkInYourLikes) {
      onLike();
      if (!loggedIn) {
        toggleToolTip('Like');
        return;
      }
    }

    const actionPromise = isCurrentTalkInYourLikes
      ? unlikeVideoAction
      : likeVideoAction;

    actionPromise().catch(error => {
      sendErrorToServices('Uncaught like action failure', error);
    });
  };

  useEffectOnMount(() => {
    const url = createSignInRedirectUrl(AuthRedirectType.AddToList, {
      id: itemIdToAdd
    });
    setRedirectURL(url);
  });

  const renderDefaultComponent = () => (
    <ButtonTooltip
      isVisible={visibleTooltip === 'Like'}
      onHide={hideTooltip}
      renderedTooltip={
        <SignInTooltip
          actionConclusionMessage="to like videos"
          redirectURL={redirectURL}
        />
      }
      context="Like"
    >
      <TalkActionButton
        ref={buttonRef}
        testId="LikeActionButton_TESTID"
        disabled={isActionRunning && loggedIn}
        iconName={isCurrentTalkInYourLikes ? 'heart-filled' : 'heart'}
        onClick={handleClick}
        label={isCurrentTalkInYourLikes ? 'Liked' : 'Like'}
      />
    </ButtonTooltip>
  );

  return CustomComponent ? (
    <CustomComponent
      handleClick={() => {
        if (mixpanelTitle)
          mixpanel.track('homepage_click', {
            current_url: window.location.href,
            title: mixpanelTitle,
            item_number: '',
            link_type: 'like',
            value: ''
          });
        handleClick();
      }}
      isActionRunning={isActionRunning}
      isCurrentTalkInYourLikes={isCurrentTalkInYourLikes}
      isDarkMode={isDarkMode}
    />
  ) : (
    renderDefaultComponent()
  );
};

export default Like;
